<template lang="pug">
.feed(
  :class="{ subscriptor: userProfile.subscription && userProfile.subscription > new Date() - 0, course: profile.isCourse }"
)
  .profile-info.card(
    v-if="profile._id && userProfile",
    style="margin-bottom: 10px"
  )
    .card-body
      h3
        UserName(:id="profile._id")
      img.rounded-circle.profile-img.small(
        v-if="profile.profile.photo",
        :src="'https://social.emmint.com/' + profile.profile.photo"
      )
      p.small.text-start.description {{ profile.profile.description }}
      .row.details.text-start(v-if="profile.isCourse")
        .col-auto(v-if="profile.data.profileImg")
          img.rounded-circle(
            :src="profile.data.profileImg",
            alt="profilePhoto",
            style="width: 100px"
          )
        .col
          span.author(v-if="profile.data.author") By:
            span.fw-bold {{ profile.data.author }}
            br
          span.year(v-if="profile.data.year") 
            span.material-icons date_range
            |
            | {{ profile.data.year }}
            br
          span.duration.mutted.fs-6.fw-light(v-if="profile.data.duration") 
            span.material-icons timer
            |
            | {{ profile.data.duration }}
            br
          span.lang
            span.material-icons language
            |
            | {{ profile.profile.language }}
            br
      .profile-actions.text-end
        button.btn.btn-outline-secondary.m-1(
          @click="$router.push('/Me')",
          v-if="profile._id == UserStore.me._id"
        ) {{ $t('message.settings') }}
        button.btn.btn-outline-secondary.m-1(
          @click="UserStore.changeActiveProfile(profile._id)",
          v-if="UserStore.myProfilesMap[profile._id] && profile._id != UserStore.me._id"
        ) Change to Profile
        button.btn.subcribed-number.p-2(
          v-if="Object.keys(profile.subscribed).length && profile.isGroup",
          @click="showSubscribers = !showSubscribers"
        )
          span.material-icons group
          span {{ Object.keys(profile.subscribed).length }}
        FollowButton(
          :profile="profile",
          v-if="profile._id != UserStore.me._id"
        )

  //Pending subscriptions
  .pending.card(
    v-if="profile._id && userProfile.userid === profile.userid && profile.pending && Object.keys(profile.pending).length",
    style="margin-bottom: 10px"
  )
    .card-body.text-start
      p Pending requests:
      .pending-user(v-for="p in Object.keys(profile.pending)")
        UserName(:id="p", :key="p")
        span.material-icons.pointer(@click="acceptGroupRequest(p)") verified_user
        span.material-icons.pointer(@click="rejectGroupRequest(p)") gpp_bad
  .subscribers.card.mb-3(
    v-if="profile.isGroup && !profile.isPrivate && showSubscribers"
  )
    .card-body.text-start
      h5 {{ $t('message.subscribers') }}
      .row
        HorizontalProfilesCarousels(
          :profiles="Object.keys(profile.subscribed)"
        )
  NewPost(
    v-if="!profile.isCourse || profile._id === userProfile._id",
    :profile="profile",
    :user="$attrs.user",
    @newPost="newPost"
  )
  h4(v-if="loadingPosts") {{ $t('message.loading') }}...
  //.notifications(style="overflow: scroll; padding-bottom: 15px; margin-bottom: 5px" v-if="UserStore.me && UserStore.me.notifications && !profile._id")
    h5(
      v-for="(notif, i) in [...UserStore.me.notifications].reverse().slice(0, 10)",
      style="display: table-cell; padding-right: 10px"
    )
      span.badge.bg-secondary
        span.material-icons notifications_active
        |
        | {{ notif.body }}
  Post(
    v-for="(p, idx) in posts",
    :key="p._id",
    :post="p",
    :index="idx",
    @newCommentReaction="newCommentReaction",
    @newPostReaction="newPostReaction",
    @newPostBookmark="newPostBookmark",
    @newPostComment="newPostComment",
    :user="$attrs.user"
  )
</template>

<script>
import Post from "../components/Post.vue";
import UserName from "./../components/UserName.vue";
import NewPost from "./../components/NewPost.vue";
import FollowButton from "./../components/fundamentals/FollowButton.vue";
import HorizontalProfilesCarousels from "./../components/HorizontalProfilesCarousels.vue";
import API from "./../API.js";
import { UserStore } from "./../UserStore.js";

export default {
  name: "Feed",
  data() {
    return {
      posts: [],
      newContent: "",
      userid: API.currentUserId(),
      loadingPosts: true,
      profile: {},
      userProfile: {},
      showSubscribers: false,
      UserStore,
    };
  },
  components: {
    Post,
    UserName,
    NewPost,
    FollowButton,
    HorizontalProfilesCarousels,
  },
  methods: {
    newPost(content, isNews) {
      if (!content) return 0;
      const userProfile = this.$route.params ? this.$route.params.id : "";
      API.newPost(content, userProfile, isNews).then((newPost) => {
        if (newPost) this.posts.unshift(newPost);
      });
    },
    newPostComment(postid, postIdx, content) {
      API.newPostComment(postid, content).then((newComment) => {
        this.posts[postIdx].comments.push(newComment);
      });
    },
    newPostReaction(postid, postIdx) {
      let profileid = this.userProfile._id;
      if (!this.posts[postIdx].reactions[profileid]) {
        API.newPostReaction(postid).then(() => {
          this.posts[postIdx].reactions[profileid] = { type: "like" };
        });
      } else {
        API.removePostReaction(postid).then(() => {
          delete this.posts[postIdx].reactions[profileid];
        });
      }
    },
    newPostBookmark(postid, postIdx) {
      let profileid = this.userProfile._id;
      if (
        !this.posts[postIdx].bookmarks ||
        !this.posts[postIdx].bookmarks.includes(profileid)
      ) {
        API.newPostBookmark(postid).then(() => {
          if (!this.posts[postIdx].bookmarks)
            this.posts[postIdx].bookmarks = [];
          this.posts[postIdx].bookmarks.push(profileid);
        });
      } else {
        API.removePostReaction(postid).then(() => {
          this.posts[postIdx].bookmarks = this.posts[postIdx].bookmarks.filter(
            (id) => id != profileid
          );
        });
      }
    },
    newCommentReaction(postid, commentDate, commentIdx, postIdx) {
      let profileid = this.userProfile._id;
      if(!this.posts[postIdx].comments[commentIdx].reactions[profileid]){
        this.posts[postIdx].comments[commentIdx].reactions[profileid] = {
            type: "like",
          };
        API.newCommentReaction(postid, commentDate);
      } else {
        delete this.posts[postIdx].comments[commentIdx].reactions[profileid];
        API.removeCommentReaction(postid, commentDate);
      }
    },
    acceptGroupRequest(id) {
      delete this.profile.pending[id];
      API.acceptGroupRequest(id, this.profile._id).then((r) => {
        if (r.status != "ok") this.profile.pending[id] = new Date();
      });
    },
    rejectGroupRequest(id) {
      delete this.profile.pending[id];
      API.rejectGroupRequest(id, this.profile._id).then((r) => {
        if (r.status != "ok") this.profile.pending[id] = new Date();
      });
    },
  },
  mounted() {
    this.userProfile = JSON.parse(JSON.stringify(this.$attrs.user));
    let userid = this.$route.params ? this.$route.params.id : "";
    if (userid) {
      API.getUserProfile(this.$route.params.id, true).then((profile) => {
        this.profile = profile;
        API.getPosts(userid).then((data) => {
          if (data) this.posts = this.profile.isCourse ? data.reverse() : data;
          this.loadingPosts = false;
        });
      });
    } else {
      API.getPosts(userid).then((data) => {
        if (data) this.posts = data;
        this.loadingPosts = false;
      });
    }
  },
  watch: {
    $route() {
      this.posts = [];
      this.profile = {};
      this.userProfile = JSON.parse(JSON.stringify(this.$attrs.user));
      let userid = this.$route.params ? this.$route.params.id : "";
      if (userid) {
        API.getUserProfile(this.$route.params.id).then((profile) => {
          this.profile = profile;
          API.getPosts(userid).then((data) => {
            if (data)
              this.posts = this.profile.isCourse ? data.reverse() : data;
            this.loadingPosts = false;
          });
        });
      } else {
        API.getPosts(userid).then((data) => {
          if (data) this.posts = data;
          this.loadingPosts = false;
        });
      }
    },
  },
};
</script>

<style lang="sass">
.new-post
  margin-bottom: 20px
</style>